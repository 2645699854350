<template>
  <div>
    <b-carousel
      id="carousel-1"
      :interval="4000"
      background="#ababab"
      img-width="1024"
      img-height="480"
    >
      <b-carousel-slide :img-src="wpInfo.banner_image"></b-carousel-slide>
      <b-carousel-slide :img-src="wpInfo.banner_image_1"></b-carousel-slide>
      <b-carousel-slide
        v-if="this.$route.name === 'groups'"
        :img-src="wpInfo.banner_image_2"
      ></b-carousel-slide>
    </b-carousel>
    <b-tabs class="custom" content-class="mt-3" align="center">
      <template #tabs-end>
        <b-nav-item
          :to="{
            name: 'home',
            query: {
              tabIndex: 0,
            },
          }"
          role="presentation"
          class="navTitle"
          >FIND YOUR NEAREST CLUB
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'home',
            query: {
              tabIndex: 1,
            },
          }"
          role="presentation"
          class="navTitle"
          ><strong>DISCIPLINES</strong><br /><span class="downTri hide"></span>
        </b-nav-item>
        <b-nav-item
          :to="{
            name: 'home',
            query: {
              tabIndex: 2,
            },
          }"
          role="presentation"
          class="navTitle"
          >VOLUNTEER
        </b-nav-item>
   <!--     <b-nav-item
          :to="{
            name: 'home',
            query: {
              tabIndex: 3,
            },
          }"
          role="presentation"
          class="navTitle"
          >DOWNLOADS
        </b-nav-item> -->
      </template>
    </b-tabs>
    <div class="content">
      <b-row>
        <b-col lg="2">
          <b-nav-item
            :to="{
              name: 'home',
              query: {
                tabIndex: 1,
              },
            }"
            class="back"
          >
            &#60; Back to disciplines</b-nav-item
          >
          <b-nav-item
            v-if="this.$route.name === 'groups'"
            :to="{
              name: 'home',
              query: {
                tabIndex: 2,
              },
            }"
            class="back"
          >
            &#60; Back to groups</b-nav-item
          >
        </b-col>
        <b-col lg="9">
          <div class="outer">
            <b-img class="tImg" :src="wpInfo.top_image"></b-img>
          </div>
          <h2 class="title">
            {{ wpInfo.title }}
          </h2>
          <span class="text" v-html="wpInfo.text"></span>
          <b-row>
            <b-col class="disCol" lg="4">
              <b-nav-item :href="wpInfo.download_link" target="_blank">
                <div>
                  <b-img :src="wpInfo.download_image"></b-img>
                  <br />
                  <span
                    v-if="wpInfo.download_title !== ''"
                    :style="'background-color:' + wpInfo.button_color + ';'"
                    class="titleDown db"
                    >{{ wpInfo.download_title }}
                    <b-icon style="float: right;" icon="download"></b-icon
                  ></span>
                </div>
              </b-nav-item>
            </b-col>
            <b-col class="disCol" lg="4" v-if="wpInfo.download_link_1 !== ''">
              <b-nav-item :href="wpInfo.download_link_1" target="_blank">
                <div>
                  <b-img :src="wpInfo.download_image_1"></b-img>
                  <br />
                  <span
                    v-if="wpInfo.download_title_1 !== ''"
                    :style="'background-color:' + wpInfo.button_color + ';'"
                    class="titleDown db"
                    >{{ wpInfo.download_title_1 }}
                    <b-icon style="float: right;" icon="download"></b-icon>
                  </span>
                </div>
              </b-nav-item>
            </b-col>
            <b-col class="disCol" lg="4" v-if="wpInfo.download_link_2 !== ''">
              <b-nav-item :href="wpInfo.download_link_2" target="_blank">
                <div>
                  <b-img :src="wpInfo.download_image_2"></b-img>
                  <br />
                  <span
                    v-if="wpInfo.download_title_2 !== ''"
                    :style="'background-color:' + wpInfo.button_color + ';'"
                    class="titleDown db"
                    >{{ wpInfo.download_title_2 }}
                    <b-icon style="float: right;" icon="download"></b-icon
                  ></span>
                </div>
              </b-nav-item>
            </b-col>
          </b-row>
          <a :href="wpInfo.ad_link">
            <b-img class="mobHide" :src="wpInfo.ad_banner_bottom_mobile"></b-img
          ></a>
          <a :href="wpInfo.ad_link">
            <b-img class="mobShow" :src="wpInfo.ad_banner_bottom"></b-img
          ></a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "singlePage",
  data() {
    return {
      wpInfo: [],
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/?rest_route=/wp/v2/pages/" +
          this.$route.query.wpPage
      )
      .then((response) => {
        this.wpInfo = response.data.acf;
      });
  },
};
</script>

<style scoped>
::v-deep .custom > div > .nav-tabs {
  background-color: #201546;
  text-align: center;
  height: 100px;
}
::v-deep .custom > div > .nav-tabs > .nav-item > .nav-link {
  background-color: #201546;
  color: #fff;
  border: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .custom > div > .nav-tabs > .nav-item > .nav-link.active {
  background-color: #201546 !important;
  color: #fff !important;
  border: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .custom > div > .nav-tabs > .nav-item {
  margin-bottom: 0 !important;
  padding: 25px 0;
}
.downTri {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 12px 12.5px 0 12.5px;
  border-color: #201546 transparent transparent transparent;
  margin-left: -14px;
  margin-top: 35px;
}
.tImg {
  width: 100%;
}
li {
  list-style: none;
}
.content {
  padding: 5% 10%;
}
.outer {
  overflow: hidden;
  height: 322px;
}
.disCol > .nav-item > .nav-link {
  padding: 0;
}
.disCol {
  padding: 50px 15px;
}
.title {
  padding-top: 51px;
  padding-bottom: 41px;
  font-size: 40px;
  color: #2a5c7e;
  font-weight: bold;
}
.titleDown {
  height: 66px;
  width: 240px;
  border-radius: 0px 33px 33px 0px;
  font-size: 18px;
  color: #fff;
  margin-top: -33px;
  position: absolute;
  padding: 20px;
  text-transform: uppercase;
}
.titleDown:hover {
  font-weight: bold;
}
.text {
  font-size: 16px;
  color: 707070;
}
.nav-link {
  font-size: 18px;
  color: #00000099;
}
.mobShow {
  display: none;
}
.mobHide {
  width: 100%;
}
::v-deep .ssLink {
  color: #0491cf;
}
::v-deep .text > p > a {
  color: #0491cf !important;
}
@media only screen and (max-width: 1440px) {
  .outer {
    height: 241px;
  }
}
@media only screen and (max-width: 1024px) {
  .outer {
    height: 173px;
  }
}
@media only screen and (max-width: 768px) {
  .back {
    padding-bottom: 20px;
  }
  .mobHide {
    display: none;
  }
  .mobShow {
    display: block;
    width: 100%;
  }
  .downTri {
    display: none;
  }
}
@media only screen and (max-width: 425px) {
  .outer {
    height: 96px;
  }

  .navTitle > a {
    font-size: 14px;
    padding: 0.5rem;
  }
  ::v-deep .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    max-width: 700px;
    overflow: auto;
    /*justify-content: left !important; */
  }
}

@media only screen and (max-width: 390px) {
  .navTitle > a {
    font-size: 12px;
  }
  .outer {
    height: 84px;
  }
}
</style>
